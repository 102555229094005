// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as Char from "../../../../libs/Char.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Debounce from "rescript-debounce/src/Debounce.res.js";
import * as ReactForm from "../../../../libs/ReactForm.res.js";
import * as TextField from "../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DatePicker from "../../../../styleguide/forms/DatePicker/DatePicker.res.js";
import * as Visibility from "../../../../libs/Visibility.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as $$Notification from "../../../../bindings/Notification.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ProjectWizard from "../_components/ProjectWizard/ProjectWizard.res.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import AddDays from "date-fns/addDays";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanel from "../_components/ProjectWizardPanel/ProjectWizardPanel.res.js";
import * as ReactNotifications from "react-notifications";
import * as SignInModalContainer from "../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ProjectWizardNavigation from "../_components/ProjectWizardNavigation/ProjectWizardNavigation.res.js";
import * as CreateBareMetalProjectConfigurationForm from "./CreateBareMetalProjectConfigurationForm.res.js";
import * as CreateBareMetalProjectConfigurationScss from "./CreateBareMetalProjectConfiguration.scss";

var css = CreateBareMetalProjectConfigurationScss;

function domId(field, at) {
  return "bare-metal-rfp-project-configuration-" + (field + ("-" + String(at)));
}

function estimatedServersDomId(field, at) {
  return domId("estimated-servers-" + field, at);
}

function estimatedBudgetDomId(field, at) {
  return domId("estimated-budget-" + field, at);
}

function configurationTitle(at) {
  return "#" + (String(at + 1 | 0) + (Char.mdash + "Bare Metal RFP Configuration"));
}

function add(x, status) {
  return Belt_Array.concat(x, [status]);
}

function remove(x, index) {
  return Belt_Array.keepWithIndex(x, (function (param, idx) {
                return index !== idx;
              }));
}

function toggle(x, index) {
  return Belt_Array.mapWithIndex(x, (function (idx, visibility) {
                if (index === idx) {
                  return Visibility.toggle(visibility);
                } else {
                  return visibility;
                }
              }));
}

function expandAll(x) {
  return Belt_Array.map(x, (function (param) {
                return "Shown";
              }));
}

var Visibilities = {
  add: add,
  remove: remove,
  toggle: toggle,
  expandAll: expandAll
};

function validate(form) {
  Belt_Array.forEachWithIndex(form.input.configurations, (function (ix, param) {
          form.blurConfigurationStartDate(ix);
        }));
}

function results(form) {
  return Belt_Array.mapWithIndex(form.input.configurations, (function (ix, param) {
                return form.configurationStartDateResult(ix);
              }));
}

function isValid(results) {
  return Belt_Array.every(results, (function (result) {
                if (result !== undefined && result.TAG === "Ok") {
                  return true;
                } else {
                  return false;
                }
              }));
}

var ValidateSaveAndContinueLaterForm = {
  validate: validate,
  results: results,
  isValid: isValid
};

var persistDraft = Debounce.make(900, (function (param) {
        if (param[1].TAG === "Draft") {
          return $$Promise.wait(Api.saveBareMetalProjectConfigurationDraft(param[0]), (function (x) {
                        if (x.TAG === "Ok") {
                          return $$Notification.NotificationManager.success("Draft saved!", "", 1000);
                        }
                        SentryLogger.error1({
                              rootModule: "CreateBareMetalProjectConfiguration",
                              subModulePath: {
                                hd: "Api",
                                tl: /* [] */0
                              },
                              value: "persistDraft",
                              fullPath: "CreateBareMetalProjectConfiguration.Api.persistDraft"
                            }, "SaveBareMetalProjectDraft::Error", [
                              "Error",
                              x._0
                            ]);
                        $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                      }));
        }
        
      }));

function submitForm(data, context, onFailure) {
  if (context.TAG === "Draft") {
    return $$Promise.wait(Api.saveBareMetalProjectConfiguration(data), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.BareMetal.Provider.newSelector);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateBareMetalProjectConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "submitForm",
                        fullPath: "CreateBareMetalProjectConfiguration.Api.submitForm"
                      }, "SaveBareMetalProjectConfiguration::Error", [
                        "Error",
                        x._0
                      ]);
                  onFailure();
                  $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                }));
  }
  var projectId = context.projectId;
  $$Promise.wait(Api.updateBareMetalProjectConfiguration(projectId, data), (function (x) {
          if (x.TAG === "Ok") {
            return Url.visit(Routes_Project.BareMetal.Provider.editSelector(projectId));
          }
          SentryLogger.error1({
                rootModule: "CreateBareMetalProjectConfiguration",
                subModulePath: {
                  hd: "Api",
                  tl: /* [] */0
                },
                value: "submitForm",
                fullPath: "CreateBareMetalProjectConfiguration.Api.submitForm"
              }, "UpdateBareMetalProjectConfiguration::Error", [
                "Error",
                x._0
              ]);
          onFailure();
          $$Notification.NotificationManager.error("Something went wrong trying to update the project", "", 1000);
        }));
}

function saveDraftProjectAndContinueLater(form, context) {
  if (context.TAG !== "Draft") {
    return $$Promise.wait(Api.updateBareMetalDraftProjectAndContinueLater(context.projectId, form.input), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.index);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateBareMetalProjectConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "saveDraftProjectAndContinueLater",
                        fullPath: "CreateBareMetalProjectConfiguration.Api.saveDraftProjectAndContinueLater"
                      }, "UpdateBareMetaProjectConfiguration::Error", [
                        "Error",
                        x._0
                      ]);
                  $$Notification.NotificationManager.error("Something went wrong trying to update the project", "", 1000);
                }));
  }
  validate(form);
  if (isValid(results(form))) {
    return $$Promise.wait(Api.saveBareMetalDraftProjectAndContinueLater("BareMetal", {
                    TAG: "BareMetal",
                    _0: "Rfp"
                  }, "Draft"), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.index);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateBareMetalProjectConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "saveDraftProjectAndContinueLater",
                        fullPath: "CreateBareMetalProjectConfiguration.Api.saveDraftProjectAndContinueLater"
                      }, "saveDraftProjectAndContinueLater::saveProjectDraft::Error", [
                        "Error",
                        x._0
                      ]);
                  $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                }));
  }
  
}

function cancelCreation() {
  $$Promise.wait(Api.cancelProjectCreation(), (function (x) {
          if (x.TAG === "Ok") {
            return Url.visit(Routes_Project.index);
          } else {
            return SentryLogger.error1({
                        rootModule: "CreateBareMetalProjectConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "cancelCreation",
                        fullPath: "CreateBareMetalProjectConfiguration.Api.cancelCreation"
                      }, "CancelProjectCreation::Error", [
                        "Error",
                        x._0
                      ]);
          }
        }));
}

var Api$1 = {
  persistDraft: persistDraft,
  submitForm: submitForm,
  saveDraftProjectAndContinueLater: saveDraftProjectAndContinueLater,
  cancelCreation: cancelCreation
};

function CreateBareMetalProjectConfiguration(props) {
  var userLoginStatus = props.userLoginStatus;
  var mobile = props.mobile;
  var options = props.options;
  var context = props.context;
  var initialInput = React.useMemo((function () {
          if (context.TAG !== "Draft") {
            return {
                    configurations: context.data
                  };
          }
          var data = context.data;
          if (data !== undefined) {
            return {
                    configurations: data
                  };
          } else {
            return {
                    configurations: [CreateBareMetalProjectConfigurationForm.Configuration.empty()]
                  };
          }
        }), []);
  var match = React.useState(function () {
        return "Hidden";
      });
  var toggleSignInModal = match[1];
  var signInModal = match[0];
  var form = CreateBareMetalProjectConfigurationForm.useForm(initialInput, (function (output, form) {
          submitForm(output, context, form.notifyOnFailure);
        }));
  var initialState = React.useMemo((function () {
          var data;
          if (context.TAG === "Draft") {
            var data$1 = context.data;
            if (data$1 !== undefined) {
              data = data$1;
            } else {
              var shown = ["Shown"];
              return {
                      panelsVisibility: shown,
                      userLoginStatus: userLoginStatus
                    };
            }
          } else {
            data = context.data;
          }
          var shown$1 = Belt_Array.map(data, (function (param) {
                  return "Shown";
                }));
          return {
                  panelsVisibility: shown$1,
                  userLoginStatus: userLoginStatus
                };
        }), []);
  var match$1 = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "AddPanel") {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        panelsVisibility: Belt_Array.concat(state.panelsVisibility, ["Shown"]),
                        userLoginStatus: state.userLoginStatus
                      },
                      _1: (function (param) {
                          form.addConfiguration(CreateBareMetalProjectConfigurationForm.Configuration.empty());
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        panelsVisibility: Belt_Array.map(state.panelsVisibility, (function (param) {
                                return "Shown";
                              })),
                        userLoginStatus: state.userLoginStatus
                      }
                    };
            }
          }
          switch (action.TAG) {
            case "RemovePanel" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: remove(state.panelsVisibility, action.at),
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "TogglePanelVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: toggle(state.panelsVisibility, action.at),
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleLoginStatus" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          userLoginStatus: action._0
                        },
                        _1: (function (param) {
                            saveDraftProjectAndContinueLater(form, context);
                          })
                      };
            
          }
        }));
  var dispatch = match$1[1];
  var state = match$1[0];
  var match$2 = state.userLoginStatus;
  var tmp;
  if (match$2 === "LoggedIn") {
    var tmp$1;
    tmp$1 = context.TAG === "Draft" ? "Next" : "Save and Next";
    var match$3 = form.status;
    var tmp$2;
    tmp$2 = typeof match$3 !== "object" || match$3.TAG !== "Submitting" ? "Enabled" : "Busy";
    tmp = [
      tmp$1,
      (function () {
          form.submit();
        }),
      tmp$2
    ];
  } else {
    tmp = [
      "Next",
      (function () {
          toggleSignInModal(function (param) {
                return Visibility.toggle(signInModal);
              });
        }),
      "Enabled"
    ];
  }
  var match$4 = state.userLoginStatus;
  var tmp$3;
  tmp$3 = match$4 === "LoggedIn" && props.projectStatus === "Draft" ? [
      "Save and Continue Later",
      (function () {
          saveDraftProjectAndContinueLater(form, context);
        })
    ] : undefined;
  var tmp$4;
  tmp$4 = signInModal === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
          close: (function () {
              toggleSignInModal(function (param) {
                    return Visibility.toggle(signInModal);
                  });
            }),
          callback: (function () {
              dispatch({
                    TAG: "ToggleLoginStatus",
                    _0: "LoggedIn"
                  });
              Url.visit(Routes_Project.BareMetal.$$new);
            }),
          setUserData: props.setUserData,
          subTitle: "Sign In to save the project detail and continue later"
        }) : null;
  return JsxRuntime.jsxs(ProjectWizard.Container.make, {
              children: [
                JsxRuntime.jsxs(ProjectWizard.Content.make, {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("h1", {
                                    children: "Step 1: Configure Your Bare Metal Service",
                                    className: css.title
                                  }),
                              className: css.tooltipAndHeadingWrapper
                            }),
                        JsxRuntime.jsx(ProjectWizard.H2.make, {
                              children: "Add Your Regions and Operating Systems"
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Please add at least one bare metal configuration with a region and operating system.",
                              className: css.subHeading
                            }),
                        JsxRuntime.jsx("div", {
                              children: Belt_Array.mapWithIndex(form.input.configurations, (function (configurationIndex, configuration) {
                                      var visibility = state.panelsVisibility[configurationIndex];
                                      var match = form.input.configurations;
                                      var tmp;
                                      if (visibility === "Shown") {
                                        var domId$1 = domId("start-date", configurationIndex);
                                        var result = form.configurationStartDateResult(configurationIndex);
                                        var tmp$1;
                                        tmp$1 = result !== undefined && result.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$2;
                                        tmp$2 = result !== undefined && result.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$2 = domId("contract-length", configurationIndex);
                                        var result$1 = form.configurationContractLengthResult(configurationIndex);
                                        var tmp$3;
                                        tmp$3 = result$1 !== undefined && result$1.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$4;
                                        tmp$4 = result$1 !== undefined && result$1.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$1._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$3 = estimatedServersDomId("estimated-servers", configurationIndex);
                                        var result$2 = form.configurationEstimatedServersResult(configurationIndex);
                                        var tmp$5;
                                        tmp$5 = result$2 !== undefined && result$2.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$6;
                                        tmp$6 = result$2 !== undefined && result$2.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$2._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$4 = estimatedBudgetDomId("estimated-budget", configurationIndex);
                                        var result$3 = form.configurationEstimatedBudgetResult(configurationIndex);
                                        var tmp$7;
                                        tmp$7 = result$3 !== undefined && result$3.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$8;
                                        tmp$8 = result$3 !== undefined && result$3.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$3._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var result$4 = form.configurationRegionsResult(configurationIndex);
                                        var tmp$9;
                                        tmp$9 = result$4 !== undefined && result$4.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$4._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var result$5 = form.configurationOperatingSystemsResult(configurationIndex);
                                        var tmp$10;
                                        tmp$10 = result$5 !== undefined && result$5.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$5._0,
                                                className: css.errorMessage
                                              }) : null;
                                        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                              children: [
                                                JsxRuntime.jsxs(ProjectWizardPanel.HeadlessSection.make, {
                                                      children: [
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$1,
                                                                              status: result,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$1
                                                                                  ]),
                                                                              children: "Service Start Date"
                                                                            }),
                                                                        JsxRuntime.jsx(DatePicker.make, {
                                                                              id: domId$1,
                                                                              value: Belt_Option.map(configuration.startDate, (function (prim) {
                                                                                      return prim;
                                                                                    })),
                                                                              fromMonth: Caml_option.some(AddDays(new Date(), 1)),
                                                                              disabledDays: [{
                                                                                  from: new Date(0, 0),
                                                                                  to: new Date()
                                                                                }],
                                                                              status: Belt_Option.map(result, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              onDayChange: (function (date, param, param$1) {
                                                                                  form.updateConfigurationStartDate(configurationIndex, (function (input, value) {
                                                                                          return CreateBareMetalProjectConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: value,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                estimatedServers: configuration.estimatedServers,
                                                                                                                estimatedBudget: configuration.estimatedBudget,
                                                                                                                regions: configuration.regions,
                                                                                                                operatingSystems: configuration.operatingSystems
                                                                                                              };
                                                                                                      }));
                                                                                        }), Caml_option.some(date));
                                                                                  persistDraft([
                                                                                        CreateBareMetalProjectConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: Caml_option.some(date),
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        estimatedServers: configuration.estimatedServers,
                                                                                                        estimatedBudget: configuration.estimatedBudget,
                                                                                                        regions: configuration.regions,
                                                                                                        operatingSystems: configuration.operatingSystems
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onHide: (function () {
                                                                                  form.blurConfigurationStartDate(configurationIndex);
                                                                                }),
                                                                              className: css.input
                                                                            }),
                                                                        tmp$2
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$2,
                                                                              status: result$1,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$3
                                                                                  ]),
                                                                              children: "Contract Length"
                                                                            }),
                                                                        JsxRuntime.jsxs(Select.make, {
                                                                              id: domId$2,
                                                                              value: Belt_Option.mapWithDefault(configuration.contractLength, "", (function (cl) {
                                                                                      return String(cl.value);
                                                                                    })),
                                                                              status: Belt_Option.map(result$1, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              wrapperClassName: css.selectWrapper,
                                                                              iconSize: "XXS",
                                                                              iconColor: "GrayText",
                                                                              onChange: (function ($$event) {
                                                                                  var value = Belt_Array.getBy(options.contractLengths, (function (cl) {
                                                                                          return Belt_Option.mapWithDefault(Belt_Int.fromString(ReactForm.value($$event)), false, (function (value) {
                                                                                                        return value === cl.value;
                                                                                                      }));
                                                                                        }));
                                                                                  form.updateConfigurationContractLength(configurationIndex, (function (input, value) {
                                                                                          return CreateBareMetalProjectConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: value,
                                                                                                                estimatedServers: configuration.estimatedServers,
                                                                                                                estimatedBudget: configuration.estimatedBudget,
                                                                                                                regions: configuration.regions,
                                                                                                                operatingSystems: configuration.operatingSystems
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateBareMetalProjectConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: value,
                                                                                                        estimatedServers: configuration.estimatedServers,
                                                                                                        estimatedBudget: configuration.estimatedBudget,
                                                                                                        regions: configuration.regions,
                                                                                                        operatingSystems: configuration.operatingSystems
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationContractLength(configurationIndex);
                                                                                }),
                                                                              children: [
                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                      children: "Select..."
                                                                                    }),
                                                                                Belt_Array.map(options.contractLengths, (function (cl) {
                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                    value: String(cl.value),
                                                                                                    children: cl.label
                                                                                                  }, String(cl.value));
                                                                                      }))
                                                                              ]
                                                                            }),
                                                                        tmp$4
                                                                      ],
                                                                      className: css.field
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.unfilled
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$3,
                                                                              status: result$2,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$5
                                                                                  ]),
                                                                              children: "Estimated Servers"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$3,
                                                                              value: Belt_Option.mapWithDefault(configuration.estimatedServers, "", (function (prim) {
                                                                                      return String(prim);
                                                                                    })),
                                                                              status: Belt_Option.map(result$2, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationEstimatedServers(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var x = ReactForm.value($$event);
                                                                                  var value;
                                                                                  if (x === "") {
                                                                                    value = {
                                                                                      TAG: "Ok",
                                                                                      _0: undefined
                                                                                    };
                                                                                  } else {
                                                                                    var n = Belt_Int.fromString(x);
                                                                                    value = n !== undefined && String(n) === x ? ({
                                                                                          TAG: "Ok",
                                                                                          _0: n
                                                                                        }) : ({
                                                                                          TAG: "Error",
                                                                                          _0: undefined
                                                                                        });
                                                                                  }
                                                                                  if (value.TAG !== "Ok") {
                                                                                    return ;
                                                                                  }
                                                                                  var value$1 = value._0;
                                                                                  form.updateConfigurationEstimatedServers(configurationIndex, (function (input, value) {
                                                                                          return CreateBareMetalProjectConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                estimatedServers: value,
                                                                                                                estimatedBudget: configuration.estimatedBudget,
                                                                                                                regions: configuration.regions,
                                                                                                                operatingSystems: configuration.operatingSystems
                                                                                                              };
                                                                                                      }));
                                                                                        }), value$1);
                                                                                  persistDraft([
                                                                                        CreateBareMetalProjectConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        estimatedServers: value$1,
                                                                                                        estimatedBudget: configuration.estimatedBudget,
                                                                                                        regions: configuration.regions,
                                                                                                        operatingSystems: configuration.operatingSystems
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$6
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$4,
                                                                              status: result$3,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$7
                                                                                  ]),
                                                                              children: "Est. Monthly Budget"
                                                                            }),
                                                                        JsxRuntime.jsxs(Select.make, {
                                                                              id: domId$4,
                                                                              value: Belt_Option.mapWithDefault(configuration.estimatedBudget, "", (function (value) {
                                                                                      return value;
                                                                                    })),
                                                                              status: Belt_Option.map(result$3, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              wrapperClassName: css.selectWrapper,
                                                                              iconSize: "XXS",
                                                                              iconColor: "GrayText",
                                                                              onChange: (function ($$event) {
                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.estimatedBudgets, (function (x) {
                                                                                              return ReactForm.value($$event) === x;
                                                                                            })), "");
                                                                                  form.updateConfigurationEstimatedBudget(configurationIndex, (function (input, value) {
                                                                                          return CreateBareMetalProjectConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                estimatedServers: configuration.estimatedServers,
                                                                                                                estimatedBudget: value,
                                                                                                                regions: configuration.regions,
                                                                                                                operatingSystems: configuration.operatingSystems
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateBareMetalProjectConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        estimatedServers: configuration.estimatedServers,
                                                                                                        estimatedBudget: value,
                                                                                                        regions: configuration.regions,
                                                                                                        operatingSystems: configuration.operatingSystems
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationEstimatedBudget(configurationIndex);
                                                                                }),
                                                                              children: [
                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                      children: "Select..."
                                                                                    }),
                                                                                Belt_Array.map(options.estimatedBudgets, (function (x) {
                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                    value: x,
                                                                                                    children: x
                                                                                                  }, x);
                                                                                      }))
                                                                              ]
                                                                            }),
                                                                        tmp$8
                                                                      ],
                                                                      className: css.field
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.unfilled
                                                                  ])
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsxs(ProjectWizardPanel.Section.make, {
                                                      title: "Regions",
                                                      children: [
                                                        JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                              children: [
                                                                JsxRuntime.jsx("div", {
                                                                      children: JsxRuntime.jsx("p", {
                                                                            children: "Select the general regions where your bare metal services are needed."
                                                                          })
                                                                    }),
                                                                tmp$9
                                                              ]
                                                            }),
                                                        JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                                              items: options.regions,
                                                              mobile: mobile,
                                                              children: (function (region) {
                                                                  var domId$5 = domId("regions-" + region, configurationIndex);
                                                                  return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                              id: domId$5,
                                                                              size: "MD",
                                                                              checked: Belt_SetString.has(configuration.regions, region),
                                                                              containerClassName: css.checkbox,
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.checked($$event) ? Belt_SetString.add(configuration.regions, region) : Belt_SetString.remove(configuration.regions, region);
                                                                                  form.updateConfigurationRegions(configurationIndex, (function (input, value) {
                                                                                          return CreateBareMetalProjectConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                estimatedServers: configuration.estimatedServers,
                                                                                                                estimatedBudget: configuration.estimatedBudget,
                                                                                                                regions: value,
                                                                                                                operatingSystems: configuration.operatingSystems
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateBareMetalProjectConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        estimatedServers: configuration.estimatedServers,
                                                                                                        estimatedBudget: configuration.estimatedBudget,
                                                                                                        regions: value,
                                                                                                        operatingSystems: configuration.operatingSystems
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationRegions(configurationIndex);
                                                                                }),
                                                                              children: region
                                                                            }, domId$5);
                                                                })
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsxs(ProjectWizardPanel.Section.make, {
                                                      title: "Operating Systems",
                                                      children: [
                                                        JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                              children: [
                                                                JsxRuntime.jsx("div", {
                                                                      children: JsxRuntime.jsx("p", {
                                                                            children: "Select the type of operating systems your bare metal services will need."
                                                                          })
                                                                    }),
                                                                tmp$10
                                                              ]
                                                            }),
                                                        JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                                              items: options.operatingSystems,
                                                              mobile: mobile,
                                                              children: (function (operatingSystem) {
                                                                  var domId$5 = domId("operating-systems-" + operatingSystem, configurationIndex);
                                                                  return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                              id: domId$5,
                                                                              size: "MD",
                                                                              checked: Belt_SetString.has(configuration.operatingSystems, operatingSystem),
                                                                              containerClassName: css.checkbox,
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.checked($$event) ? Belt_SetString.add(configuration.operatingSystems, operatingSystem) : Belt_SetString.remove(configuration.operatingSystems, operatingSystem);
                                                                                  form.updateConfigurationOperatingSystems(configurationIndex, (function (input, value) {
                                                                                          return CreateBareMetalProjectConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                estimatedServers: configuration.estimatedServers,
                                                                                                                estimatedBudget: configuration.estimatedBudget,
                                                                                                                regions: configuration.regions,
                                                                                                                operatingSystems: value
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateBareMetalProjectConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        estimatedServers: configuration.estimatedServers,
                                                                                                        estimatedBudget: configuration.estimatedBudget,
                                                                                                        regions: configuration.regions,
                                                                                                        operatingSystems: value
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationOperatingSystems(configurationIndex);
                                                                                }),
                                                                              children: operatingSystem
                                                                            }, domId$5);
                                                                })
                                                            })
                                                      ]
                                                    })
                                              ]
                                            });
                                      } else {
                                        tmp = null;
                                      }
                                      return JsxRuntime.jsx(ProjectWizardPanel.make, {
                                                  title: configurationTitle(configurationIndex),
                                                  visibility: visibility,
                                                  toggleVisibility: (function () {
                                                      dispatch({
                                                            TAG: "TogglePanelVisibility",
                                                            at: configurationIndex
                                                          });
                                                    }),
                                                  deleteControl: match.length !== 1 ? (function () {
                                                        form.removeConfiguration(configurationIndex);
                                                        dispatch({
                                                              TAG: "RemovePanel",
                                                              at: configurationIndex
                                                            });
                                                        persistDraft([
                                                              CreateBareMetalProjectConfigurationForm.Configuration.remove(form.input, configurationIndex),
                                                              context
                                                            ]);
                                                      }) : undefined,
                                                  children: tmp
                                                }, configuration.key);
                                    }))
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              onClick: (function (param) {
                                  dispatch("AddPanel");
                                }),
                              children: "+ Add One More Bare Metal Service"
                            })
                      ]
                    }),
                JsxRuntime.jsx(ProjectWizardNavigation.make, {
                      stepLabel: "Step 1 of 3",
                      controls: {
                        TAG: "PreviousAndNextAndContinueLater",
                        next: tmp,
                        previous: [
                          "Cancel",
                          (function () {
                              cancelCreation();
                            }),
                          "Enabled"
                        ],
                        continueLater: tmp$3
                      },
                      mobile: mobile
                    }),
                JsxRuntime.jsx(ReactNotifications.NotificationContainer, {}),
                tmp$4
              ]
            });
}

var Configuration;

var Options;

var make = CreateBareMetalProjectConfiguration;

export {
  Configuration ,
  Options ,
  css ,
  domId ,
  estimatedServersDomId ,
  estimatedBudgetDomId ,
  configurationTitle ,
  Visibilities ,
  ValidateSaveAndContinueLaterForm ,
  Api$1 as Api,
  make ,
}
/* css Not a pure module */
